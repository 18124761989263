import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import {
  Disbursement,
  DisbursementDetail,
  Fees,
  PaymentSchedule,
} from '@interfaces/manage-monitor-cashflow'
import { Option, Select } from '@material-tailwind/react'
import { CashflowService } from '@services/api-manage/monitor-cashflow'

interface PaymentSchedulesProps {
  facility?: Facility
}

const PaymentSchedules = ({ facility }: PaymentSchedulesProps) => {
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)

  const { activeFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.[activeFacilityId - 1]

  const PER_PAGE = 15
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([])

  const [selectedDisbursement, setSelectedDisbursement] = useState<
    string | undefined
  >('')

  // If this is for a single facility
  const filters = {
    slugName:
      facility?.slug_name ??
      activeFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility: facility?.facility_id ?? activeFacility?.facility_id ?? '',
  }
  const listFilters = {
    ...filters,
    page: page + 1,
    perPage: perPage,
    disbursementDate:
      selectedDisbursement == ''
        ? 'summary'
        : moment(selectedDisbursement).format('YYYY-MM-DD'),
  }
  const { data: disbursementList } = useQuery(
    ['disbursementList', filters],
    () => CashflowService.getDisbursementSummary(filters)
  )

  const { data, isFetching } = useQuery(
    ['paymentScheduleList', listFilters],
    () => CashflowService.getAllDisbursementdata(listFilters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility?.facility_id && !!company?.slug_name,
    }
  )

  const tableData = data?.data ?? []

  const expandedRowRender = (record: any) => {
    return (
      <div className="[&>div]:!mb-0 [&>div>div]:!rounded-none [&>div:first-child>div]:!rounded-t-lg [&>div:last-child>div]:!rounded-b-lg">
        {record.aggregated_data.map((d: Disbursement) => {
          const expandedColumns = [
            {
              title: d.disbursement.includes('Previous')
                ? 'Previous Disbursement'
                : `Disbursement ${moment(d.disbursement).format('YYYY-MM-DD')}`,
              field: 'investor',
              align: 'center',
              head: {
                colSpan: 8,
              },
              className: 'min-w-[150px]',
              render: (r: DisbursementDetail) => r.investor.replace('_', ' '),
            },
            {
              field: 'start_date',
              align: 'center',
              head: { colSpan: 0 },
              className: 'min-w-[150px]',
              render: (r: DisbursementDetail) =>
                r.start_date != 'Start Date'
                  ? moment.utc(r.start_date).format('YYYY-MM-DD')
                  : 'Start Date',
            },
            {
              field: 'end_date',
              align: 'center',
              head: { colSpan: 0 },
              className: 'min-w-[150px]',
              render: (r: DisbursementDetail) =>
                r.end_date != 'End Date'
                  ? moment.utc(r.end_date).format('YYYY-MM-DD')
                  : 'End Date',
            },
            {
              field: 'no_of_days',
              align: 'center',
              head: { colSpan: 0 },
              className: 'min-w-[150px]',
              render: (r: DisbursementDetail) =>
                !!isNaN(r.no_of_days) ? r.no_of_days : r.no_of_days,
            },
            {
              field: 'invested_amount',
              align: 'center',
              head: { align: 'center', colSpan: 0 },
              className: 'min-w-[150px]',
              render: (record: DisbursementDetail) =>
                !isNaN(record.invested_amount)
                  ? Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 2,
                    }).format(record.invested_amount)
                  : 'Invested Amount',
            },
            {
              field: 'outstanding_principal',
              align: 'center',
              head: { align: 'center', colSpan: 0 },
              className: 'min-w-[150px]',
              render: (record: DisbursementDetail) =>
                !isNaN(record.outstanding_principal)
                  ? Intl.NumberFormat(undefined, {
                      maximumFractionDigits: 2,
                    }).format(record.outstanding_principal)
                  : 'Outstanding Principal',
            },
            {
              field: 'fees',
              align: 'center',
              head: { align: 'center', colSpan: 0 },
              className: 'min-w-[150px]',
              render: (record: DisbursementDetail) => {
                return record.fees ? (
                  <Table
                    key={`${record.investor}-${record.start_date}-${record.end_date}`}
                    containerClass={`[&>table>thead>tr>td]:font-semibold [&>table>thead>tr>td]:capitalize [&>table>thead>tr>th]:capitalize [&>table>tbody>tr>td:first-child]:capitalize`}
                    columns={
                      [
                        {
                          field: 'fee_type',
                          align: 'center',
                          head: { align: 'center', colSpan: 0 },
                          className: 'min-w-[150px]',
                        },
                        {
                          field: 'percentage_interest',
                          align: 'center',
                          head: { align: 'center', colSpan: 0 },
                          className: 'min-w-[150px]',
                          render: (f: Fees) =>
                            !!isNaN(f.percentage_interest)
                              ? 'Percentage'
                              : `${
                                  f.percentage_interest
                                    ? f.percentage_interest
                                    : 0
                                }%`,
                        },
                        {
                          field: 'amount',
                          align: 'center',
                          head: { align: 'center', colSpan: 0 },
                          className: 'min-w-[150px]',
                          render: (f: Fees) =>
                            !!isNaN(f.amount)
                              ? 'Amount'
                              : Intl.NumberFormat(undefined, {
                                  maximumFractionDigits: 2,
                                }).format(f.amount),
                        },
                      ] as any
                    }
                    data={record.fees}
                    headerData={[
                      {
                        fee_type: 'Fee type',
                        percentage_interest: 'Percentage',
                        amount: 'Amount',
                      },
                    ]}
                  />
                ) : (
                  'Fees'
                )
              },
            },
          ]
          return d.data.length > 0 ? (
            <Table
              key={`${d.disbursement}-${record.schd_no}`}
              containerClass={`[&>table>thead>tr>td]:font-semibold [&>table>thead>tr>td]:capitalize [&>table>thead>tr>th]:capitalize [&>table>tbody>tr>td:first-child]:capitalize`}
              columns={expandedColumns as any}
              data={d.data}
              headerData={[
                {
                  investor: 'lender',
                  start_date: 'Start Date',
                  end_date: 'End Date',
                  no_of_days: '# of Days',
                  invested_amount: 'Disbursed Amount',
                  interest: 'Fees',
                },
              ]}
            />
          ) : null
        })}
      </div>
    )
  }

  const onExpand = (index: number) => {
    const indexes = expandedIndexes.includes(index)
      ? expandedIndexes.filter(i => i !== index)
      : [...expandedIndexes, index]

    setExpandedIndexes(indexes)
  }

  useEffect(() => {
    setPage(0)
  }, [selectedDisbursement, perPage])

  useEffect(() => {
    setExpandedIndexes([])
  }, [page, selectedDisbursement])

  const disbursementData: any = disbursementList?.data

  return (
    <div className="flex flex-col p-4">
      <div className="flex justify-end mb-6 gap-6">
        <div className="max-w-[300px] [&>div]:!min-w-[200px]">
          <Select
            className="[&~ul]:max-h-[200px]"
            onChange={(val: any) => {
              setSelectedDisbursement(val)
            }}
            selected={() => {
              return <span>{selectedDisbursement || 'Summary'}</span>
            }}
          >
            {['', ...(disbursementData?.disbursements ?? [])].map(
              (d: string) => (
                <Option key={d} value={d}>
                  {d || 'Summary'}
                </Option>
              )
            )}
          </Select>
        </div>
      </div>
      <div className="flex justify-end mb-6 grid grid-cols-4 gap-6">
        {disbursementData &&
          Object.keys(disbursementData)
            .filter(k => k != 'disbursements')
            .map(k => {
              return (
                <div
                  key={k}
                  className="rounded-lg border border-neutral-border-2 bg-neutral-surface-1 p-3"
                >
                  <Typography className="capitalize text-sm text-right">
                    {k.split('_').join(' ')}
                  </Typography>
                  <Typography className="text-xl font-semibold text-right">
                    {isNaN(disbursementData[k])
                      ? disbursementData[k]
                      : new Intl.NumberFormat(undefined, {
                          maximumFractionDigits: 2,
                        }).format(disbursementData[k])}
                  </Typography>
                </div>
              )
            })}
      </div>
      <Table
        containerClass="[&>table>thead>tr>th]:capitalize [&>table>thead>tr>th]:border-b-4 [&>table>thead>tr:last-child>td]:border-b-4 [&>table>thead>tr>td]:font-semibold"
        columns={[
          {
            title: 'Schedule #',
            field: 'schd_no',
            align: 'center',
            className: 'min-w-[50px] left-0 shadow-inner',
          },
          {
            title: 'Period Start Date',
            field: 'start_date',
            className: 'min-w-[150px]',
            align: 'center',
            render: (record: PaymentSchedule) =>
              moment.utc(record.start_date).format('YYYY-MM-DD'),
          },
          {
            title: 'Period End Date',
            field: 'end_date',
            className: 'min-w-[150px]',
            align: 'center',
            render: (record: PaymentSchedule) =>
              moment.utc(record.end_date).format('YYYY-MM-DD'),
          },
          {
            title: '# Disbursements in Period',
            field: 'no_of_disbursements_in_period',
            align: 'center',
            className: 'max-w-[100px]',
          },
          {
            title: 'No of Days in Period',
            field: 'no_of_days',
            align: 'center',
            className: 'max-w-[100px]',
          },
          {
            title: 'Fees',
            field: 'interest',
            align: 'center',
            className: 'max-w-[100px]',
            render: (record: PaymentSchedule) =>
              !!isNaN(record.interest)
                ? 'Fees'
                : Intl.NumberFormat(undefined, {
                    maximumFractionDigits: 2,
                  }).format(record.interest),
          },
        ]}
        loading={isFetching}
        data={tableData}
        rowClass={(r: PaymentSchedule) => {
          return moment.utc(r.start_date).format('YYYY-MM') ===
            moment.utc().format('YYYY-MM')
            ? '[&>td]:!bg-cc-secondary-hover-deselected'
            : ''
        }}
        expandable={{
          expandedIndexes,
          expandedRowRender,
          onExpand,
        }}
      />
      <Pagination
        id="payment-schedule-table-pagination"
        total={data?.total ?? 0}
        page={page}
        onChangePage={p => setPage(p)}
        limit={perPage}
        limits={Array(3)
          .fill('')
          .map((_, i) => PER_PAGE * (i + 1))}
        onChangeLimit={l => setPerPage(l)}
      />
    </div>
  )
}

export default PaymentSchedules
