import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import Chart, { chartColors } from '@components/chart'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { greenToRed } from '@helpers/green-to-red'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import {
  CovenantDetailResponse,
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'
import { Tooltip } from '@material-tailwind/react'

import { getLabel, getLabelFormat } from '../helper'
import { ManageCovenantDataBuilder } from '../manage-covenant-data-builder'

interface CovenantDetailVintageProps {
  covenant: CovenantDetailResponse
  type: CovenantType
  summary: CovenantListResponse
}

const CovenantDetailTable = ({
  covenant,
  summary,
}: CovenantDetailVintageProps) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const [selectedCohort, setSelectedCohort] = useState<string>('Summary')

  const covenantBuilder = new ManageCovenantDataBuilder(
    setSelectedCohort,
    selectedCohort,
    covenant,
    summary
  )
  const {
    tableColumns,
    tableData,
    chartData,
    chartSeries,
    specialSeries,
    ySetting,
  } = covenantBuilder.build()

  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [covenant, selectedCohort])

  const xLabel = summary.params.x_axis[1].toUpperCase()

  const triggerColors = (summary.triggers ?? []).map((t, i) => ({
    color: greenToRed(
      ((i + 1) / (summary.triggers ?? []).length) * 100,
      true
    ) as string,
    label: `Trigger ${i + 1} Breached`,
  }))

  return (
    <div className="flex flex-col gap-4">
      {selectedCohort != 'Summary' && (
        <div className="my-6 grid grid-cols-4 gap-4 px-8">
          {[
            { color: '#34c700', label: 'No Trigger Breach' },
            ...triggerColors,
            { color: '#757d90', label: 'Not in Test Period' },
          ].map((t, i) => {
            return (
              <div key={i} className="text-xs flex items-center gap-1">
                <span
                  style={{
                    display: 'inline-block',
                    height: 14,
                    width: 14,
                    backgroundColor: t.color,
                    borderRadius: 4,
                  }}
                />
                {t.label}
              </div>
            )
          })}
          {chartSeries
            .filter(
              c =>
                (c.type == 'SmoothedXLineSeries' ||
                  c.type == 'StepLineSeries') &&
                !c.isSpecial
            )
            .map((t, i) => {
              return (
                <div key={i} className="text-xs flex items-center gap-1">
                  <span
                    style={{
                      display: 'inline-block',
                      height: 2,
                      width: 14,
                      backgroundColor: chartColors[i + 1] ?? t.color,
                    }}
                  />
                  {t.label}
                </div>
              )
            })}
          {specialSeries.length > 0 &&
            specialSeries[0].field != '' &&
            specialSeries.map((s: any, i: any) => {
              return (
                <div key={i} className="text-xs flex items-center gap-1">
                  <span
                    style={{
                      display: 'inline-block',
                      height: 4,
                      width: 14,
                      background: `linear-gradient(to right, ${s.color} 0%, ${s.color} 45%, white 45%, white 55%, ${s.color} 55%, ${s.color} 100%)`,
                    }}
                  />

                  {s.label}
                </div>
              )
            })}
        </div>
      )}
      <Chart
        loading={isProcessing}
        id={`${summary.covenant_id}__${company?.slug_name}_${appliedFilters.activeFacilityId}`}
        yLabel={
          selectedCohort == 'Summary'
            ? 'Total Breach count'
            : getLabel(covenant)
        }
        yFormat={selectedCohort == 'Summary' ? '' : getLabelFormat(summary)}
        ySetting={ySetting}
        xSetting={{
          renderer: {
            cellStartLocation: 0.01,
            cellEndLocation: 0.9,
          },
        }}
        xLabel={xLabel}
        xAxisType="CategoryAxis"
        data={chartData}
        series={chartSeries}
        exportable={false}
        legendSetting={{ show: false }}
      />
      <div className="flex items-center justify-between text-cc-text-primary">
        <div className="text-sm">
          Charted Cohort:&nbsp;&nbsp;
          {selectedCohort == 'Summary' ? (
            <span>
              <span className="font-semibold">Summary</span> &nbsp;&nbsp;(Click
              &apos;Cohort&apos; below to toggle Chart above)
            </span>
          ) : (
            //exception to FE date display format to improve readability for cohorts
            <span className="font-semibold">
              {moment.utc(selectedCohort).format('YYYY-MM-DD')}
            </span>
          )}
        </div>
        <Tooltip
          className="bg-white border border-cc-primary-border shadow-xl text-cc-text-primary w-[300px]"
          content={
            <span>
              {" Click the 'Cohort' on the table below to adjust the chart"}
            </span>
          }
          animate={{
            mount: { opacity: 1, y: 0 },
            unmount: { opacity: 0, y: 25 },
          }}
          placement="left"
        >
          <InformationCircleIcon className="w-7 text-primary-main cursor-help" />
        </Tooltip>
      </div>
      <Table
        containerClass="nohover overflow-auto [&>table>thead>tr>td]:font-semibold"
        data={tableData}
        columns={tableColumns as any}
      />
    </div>
  )
}

export default CovenantDetailTable
