import React, { useContext } from 'react'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import { ManageDataBuilder } from '../manage-chart-data-builder'

const MonitorDashboardTableItem = ({ item }: { item: DashboardResponse }) => {
  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate } = appliedFilters

  const builder = new ManageDataBuilder(item, calculationDate)
  const { tableData, tableColumns, displayName, dateFilterField } =
    builder.build()

  return (
    <div className="flex flex-col overflow-auto h-full gap-3">
      {item.external_name && (
        <Typography className="text-center text-lg font-semibold">
          <span className="capitalize">{displayName}</span>{' '}
          {dateFilterField && calculationDate && `as of ${calculationDate}`}
        </Typography>
      )}
      <Table data={tableData} columns={tableColumns as any} />
    </div>
  )
}
export default MonitorDashboardTableItem
