import { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import { CashflowService } from '@services/api-manage/monitor-cashflow'

export const useCoignBackupInterestReportData = (effectiveDate: Moment) => {
  const { appliedFilters, company, optionFilters } = useContext(AuthContext)
  const { facilities } = optionFilters
  const { activeFacilityId } = appliedFilters
  const activeFacility = facilities?.find(
    (facility: Facility) => facility.facility_id === activeFacilityId
  )
  const [data, setData] = useState<any>([true, {}])
  const params = {
    slugName: company?.slug_name ?? '',
    facility: activeFacility.facility_id,
    disbursementDate: 'summary',
    page: 1,
    perPage: 1000,
  }

  const { data: cashflowData, isFetching: isFetching } = useQuery(
    ['covenantData', params],
    () => CashflowService.getAllDisbursementdata(params),
    {
      ...getStaleMins(),
      enabled: !!effectiveDate,
    }
  )

  const cashflow =
    !!cashflowData && !isFetching
      ? cashflowData.data?.find(
          c =>
            moment.utc(c.end_date).endOf('day').isSameOrAfter(effectiveDate) &&
            moment
              .utc(c.start_date)
              .startOf('month')
              .isSameOrBefore(effectiveDate)
        )
      : { aggregated_data: [] }

  useEffect(() => {
    setData([
      isFetching ?? false,
      {
        cashflowData: cashflow,
      },
    ])
  }, [isFetching])

  return data
}
