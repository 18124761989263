import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { Layout, Responsive, WidthProvider } from 'react-grid-layout'

import Button from '@components/atoms/button'
import HeightObserver from '@components/other/height-observer'
import AuthContext from '@contexts/auth'
import { ChevronRightIcon, PencilIcon } from '@heroicons/react/24/outline'
import { BookmarkIcon } from '@heroicons/react/24/solid'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Tooltip,
} from '@material-tailwind/react'

import MonitorDonutChartItem from './items/donut-chart'
import MonitorDashboardGroupedGraphItem from './items/grouped-graph'
import MonitorDashboardHorizontalColumnGraphItem from './items/horizontal-column-graph'
import MonitorDashboardMultilineGraphItem from './items/multiline-graph'
import MonitorDashboardNumberTableItem from './items/number-table'
import MonitorDashboardStackedAreaItem from './items/stacked-area'
import MonitorDashboardStackedGraphItem from './items/stacked-graph'
import MonitorDashboardTableItem from './items/table'
import CovenantL4 from './covenant'
import { getInitDashboardLayout, saveDashboardLayoutToLs } from './helper'

import 'react-grid-layout/css/styles.css'
import './style.css'

const ResponsiveGridLayout = WidthProvider(Responsive)

const COLS_NUMBER = 6 // initial grid units
const DEFAULT_ITEM_HEIGHT = 4 // in grid units (fallback)
const rowHeightPx = 40

const MonitorDashboardTabL4 = ({ items }: { items: DashboardResponse[] }) => {
  const { appliedFilters, company } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const key = `${company?.slug_name}_${activeFacilityId}_${items[0].tab}`

  /* All Layouts */
  const [layouts, setLayouts] = useState<{ [key: string]: any }>({})

  // Items to display
  const sortedItems = useMemo(
    () => items.sort((a, b) => a.dashboard_id - b.dashboard_id),
    [items]
  )

  const _renderItem = (i: DashboardResponse) => {
    switch (i.display) {
      case 'multiline_graph':
        return <MonitorDashboardMultilineGraphItem item={i} />
      case 'total_stacked_graph':
      case 'stacked_graph':
        return <MonitorDashboardStackedGraphItem item={i} />
      case 'grouped_graph':
        return <MonitorDashboardGroupedGraphItem item={i} />
      case 'donut_chart':
        return <MonitorDonutChartItem item={i} />
      case 'stacked_area':
      case 'total_stacked_area':
      case 'line_graph':
        return <MonitorDashboardStackedAreaItem item={i} />
      case 'covenant':
        return <CovenantL4 type={i.display_type as any} />
      case 'number_table':
        return <MonitorDashboardNumberTableItem item={i} />
      case 'table':
        return <MonitorDashboardTableItem item={i} />
      case 'horizontal_column_graph':
        return <MonitorDashboardHorizontalColumnGraphItem item={i} />
      case 'combo_series':
        return <MonitorDashboardGroupedGraphItem item={i} />
      default:
        return <></>
    }
  }

  // This function is passed in height observer to retrieve post render height
  const updateHeight = (rawHeightPx: number, identifier: string) => {
    if (Object.keys(layouts).length > 0) {
      // variance is the number of grids to subtract from grid height. rawHeightPx height incorrectness is greater when it is smaller.
      const variance = rawHeightPx > 500 ? 3 : rawHeightPx > 300 ? 2 : 1
      const newHeight = Math.ceil(rawHeightPx / rowHeightPx) - variance
      const currentHeight = layouts.md.find(
        (item: Layout) => item.i === identifier
      )?.h

      if (currentHeight === newHeight) {
        return
      }

      const layoutsCopy = cloneDeep(layouts)
      const layoutItem = layoutsCopy.md.find(
        (item: Layout) => item.i === identifier
      )

      if (layoutItem) {
        layoutItem.h = newHeight // update in reference
        setLayouts(layoutsCopy)
      }
    }
  }

  const renderedItems = sortedItems.map((i: DashboardResponse) => {
    const itemKey = key + i.dashboard_id
    return (
      <div
        key={itemKey}
        className={`p-4 border-[1.5px] rounded-lg ${
          isEditing ? `border-cc-icon-secondary shadow-lg cursor-move` : ''
        }`}
      >
        <HeightObserver onSizeChange={updateHeight} identifier={itemKey}>
          {_renderItem(i)}
        </HeightObserver>
      </div>
    )
  })

  // Generate a default layout if none is in local storage
  const generateInitialLayout = (breakpoint: string) => {
    const layout = sortedItems.map((item: DashboardResponse, idx: number) => {
      const isBig = ['number_table', 'table'].includes(item.display)
      return {
        i: key + item.dashboard_id,
        x: breakpoint === 'md' ? idx % COLS_NUMBER : 0,
        y:
          breakpoint === 'md'
            ? Math.floor(idx / COLS_NUMBER) * DEFAULT_ITEM_HEIGHT
            : idx * DEFAULT_ITEM_HEIGHT,
        w: breakpoint === 'md' ? COLS_NUMBER : 1,
        h: isBig ? 2 : DEFAULT_ITEM_HEIGHT, // fallback initial height
        minH: isBig ? 2 : DEFAULT_ITEM_HEIGHT,
        minW: 2,
        isDraggable: isEditing,
        isResizable: isEditing,
      }
    })
    return layout
  }

  // Save layout to local storage
  const saveLayoutToLs = () => {
    setIsEditing(false)
    saveDashboardLayoutToLs(key, {
      md: layouts.md.map((item: any) => ({
        ...item,
        isDraggable: false,
        isResizable: false,
      })),
      sm: layouts.sm.map((item: any) => ({
        ...item,
        isDraggable: false,
        isResizable: false,
      })),
    })
  }

  // Called by RGL on any layout changes (drag/resize)
  const onLayoutChange = (currentLayout: any, allLayouts: any) => {
    setLayouts(allLayouts)
  }

  // Toggle isDraggable/isResizable in the layout
  useEffect(() => {
    if (layouts.md?.length || layouts.sm?.length) {
      setLayouts({
        md: layouts.md.map((item: any) => ({
          ...item,
          isDraggable: isEditing,
          isResizable: isEditing,
        })),
        sm: layouts.sm.map((item: any) => ({
          ...item,
          isDraggable: isEditing,
          isResizable: isEditing,
        })),
      })
    }
  }, [isEditing])

  // Load initial layout (from localStorage or generate new)
  useLayoutEffect(() => {
    const lsItem = getInitDashboardLayout()
    const initialLayoutFromLs = lsItem[key]

    if (!!initialLayoutFromLs) {
      setLayouts(initialLayoutFromLs)
    } else {
      setLayouts({
        md: generateInitialLayout('md'),
        sm: generateInitialLayout('sm'),
      })
    }
    // Show "Did you know?" dialog if no layout is stored

    //gives dashboards time to load as well as user breathing space after navigating to dashboards page
    if (Object.keys(lsItem).length === 0) {
      setTimeout(() => {
        setIsDialogOpen(true)
      }, 5000)
    }

    // Cleanup on unmount
    return () => {
      setLayouts({ md: [], sm: [] })
    }
  }, [activeFacilityId])

  // The "Did you know?" dialog
  const instructionDialog = (
    <Dialog
      open={isDialogOpen}
      handler={() => setIsDialogOpen(!isDialogOpen)}
      size="xl"
    >
      <DialogHeader>
        <div>{'Did you know?'}</div>
      </DialogHeader>
      <DialogBody className="text-sm">
        <div className="flex flex-col px-5">
          <div className="my-2">
            You can resize and reorganize dashboards to better suit your data
            viewing preferences.
          </div>
          <div className="w-full flex justify-center">
            <img
              src="/gifs/resize-demo.gif"
              className="flex border rounded-lg shadow-md w-40 md:w-80"
            />
          </div>
          <div className="my-2 inline">
            To enable editing, click the pencil icon.{' '}
            <PencilIcon className="w-8 p-1 border rounded-lg inline mx-2" />
            Resize items using the resizer
            <ChevronRightIcon className="w-6 inline mx-1 rotate-45 stroke-[#FF7E35]" />
            at the bottom-right corner of each dashboard and drag and drop to
            reposition them. When finished, click the save button at the
            top-right corner of the page to preserve your configuration.{' '}
            <BookmarkIcon className="w-8 p-1 border rounded-lg inline ml-2" />
          </div>
          <div className="my-2 font-bold">
            Note: Configurations are saved in your browser. Clearing the browser
            cache or using a different browser/device may reset the layout.
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={() => {
            setIsDialogOpen(!isDialogOpen)
            saveLayoutToLs()
          }}
        >
          Got it
        </Button>
      </DialogFooter>
    </Dialog>
  )

  return (
    <div>
      <div className="w-full flex justify-end mb-2">
        <Tooltip
          content={
            <div>
              <div>{isEditing ? 'Save Layout' : 'Edit Layout'}</div>
            </div>
          }
          placement="left-start"
        >
          <div>
            <Button
              onClick={isEditing ? saveLayoutToLs : () => setIsEditing(true)}
              className={
                isEditing
                  ? 'border-cc-primary-orange bg-cc-tertiary-hover-selected shadow-lg'
                  : ''
              }
            >
              {isEditing ? (
                <BookmarkIcon className="w-4" color="orange" />
              ) : (
                <PencilIcon className="w-4" />
              )}
            </Button>
          </div>
        </Tooltip>
      </div>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ md: 768, sm: 480 }}
        cols={{ md: COLS_NUMBER, sm: 1 }}
        rowHeight={rowHeightPx}
        onLayoutChange={onLayoutChange}
        autoSize={true}
        compactType={'vertical'}
        containerPadding={[0, 0]}
      >
        {renderedItems}
      </ResponsiveGridLayout>

      {instructionDialog}
    </div>
  )
}

export default MonitorDashboardTabL4
