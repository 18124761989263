import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import Table from '@components/table'
import TablePositioned from '@components/table/table-positioned'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { BorrowingBaseResponse } from '@interfaces/manage-monitor-borrowing-base'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'

import DetailTable from './detail-table'

type BBColumn = {
  title: string
  field: string
  align: string
  width: number
  head: {
    colSpan: number
  }
  render: (r: BorrowingBaseResponse) => any
}
const populateBBPositionColumns = (n: number): BBColumn[] => {
  const calculationColumns: BBColumn[] = []

  if (n === 0) {
    return []
  }

  for (let i = 0; i < n; i++) {
    calculationColumns.push({
      title: `${i + 1}`,
      field: 'display_value',
      align: 'right',
      width: 150,
      head: {
        colSpan: 0,
      },
      render: (r: BorrowingBaseResponse) => {
        const is_bold = r.highlighted
        return (
          <Typography className={`${is_bold ? 'font-semibold' : ''} text-sm`}>
            {r.display_value}
          </Typography>
        )
      },
    })
  }
  return calculationColumns
}

const BorrowingBaseL4 = () => {
  const [expandedRow, setExpandedRow] = useState<string[]>([])

  const handleOpen = (row: string): void => {
    const rows = expandedRow.includes(row)
      ? expandedRow.filter(x => x !== row)
      : [...expandedRow, row]
    setExpandedRow(rows)
  }

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId, calculationDate } = appliedFilters

  const filters = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    calculation_date: calculationDate,
  }

  // Reset expended rows when we switch facilities
  useEffect(() => {
    setExpandedRow([])
  }, [appliedFilters])

  const { data, isFetching } = useQuery(
    ['borrowing-base', filters],
    () => BorrowingBaseService.get(filters),
    { ...getStaleMins(), enabled: !!calculationDate }
  )

  const defaultCurrency = optionFilters.facilities.find(
    (obj: any) => obj.facility_id == appliedFilters.activeFacilityId
  ).agreement_currency

  /* Number of columns is determined by the max number of positions from incoming data to populate our table_column in loop style for scale. */
  const numberOfColumns =
    data && data.length > 0 ? Math.max(...data.map(x => x.position)) : 0
  const positionColumns = populateBBPositionColumns(numberOfColumns)

  /* This is the first table, the second table has deficient extracted out. */
  const table_column: any[] = [
    {
      render: (r: BorrowingBaseResponse) => {
        const is_bold = r.highlighted
        return (
          <Typography className={`${is_bold ? 'font-semibold' : ''} text-sm`}>
            {r.display_name}
          </Typography>
        )
      },
    },
    ...positionColumns,
  ]

  const _handleOpenComponent = (r: BorrowingBaseResponse) => {
    setExpandedRow([
      ...expandedRow.filter(x => x !== r.display_name),
      r.display_name,
    ])
    setTimeout(() => {
      document.getElementsByClassName(r.display_name)?.[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      })
    }, 100)
  }

  const daysDeficientData = data?.filter(
    td => td.value_type === 'days_deficient'
  )

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col [&>div]:flex-none gap-3" id="summary-table">
        <TablePositioned
          id="borrowing-base-table"
          loading={isFetching || !calculationDate}
          columns={table_column}
          title={defaultCurrency}
          data={data?.filter(
            td =>
              !td.display_name.toLowerCase().includes('days deficient') &&
              td.display_value !== null
          )}
          rowClass={(r: BorrowingBaseResponse) => {
            const surDef = r.value_type === 'surplus_deficiency'
            return `
                ${
                  r.separator &&
                  'border-t-rem border-neutral-black 3xl:border-t-[3px]'
                }
                ${
                  surDef &&
                  r.display_name === 'Surplus' &&
                  '[&>td]:!bg-success-surface'
                }
                ${
                  surDef &&
                  r.display_name === 'Deficiency' &&
                  '[&>td]:!bg-danger-surface'
                }`
          }}
          onRowClick={_handleOpenComponent}
        />
        {!!daysDeficientData?.length && (
          <Table
            loading={isFetching || !calculationDate}
            containerClass="[&>table>thead]:hidden"
            columns={table_column.slice(0, 2)}
            data={daysDeficientData}
          />
        )}
      </div>

      <div>
        {data
          ?.filter(item => item.detail_data?.length > 0)
          .map((item, i) => {
            const is_open = expandedRow.includes(item.display_name)
            const Icon = is_open ? ChevronDownIcon : ChevronRightIcon
            return (
              <Accordion
                key={i}
                open={is_open}
                icon={<span></span>}
                className={`border-t border-neutral-border ${item.display_name} `}
              >
                <AccordionHeader
                  onClick={() => handleOpen(item.display_name)}
                  className="text-lg border-0 justify-start"
                >
                  <Icon className="w-6 h-6 mr-2" />
                  <span className="text-left capitalize">
                    {item.display_name}
                  </span>
                </AccordionHeader>
                <AccordionBody>
                  <DetailTable data={item.detail_data ?? []} />
                </AccordionBody>
              </Accordion>
            )
          })}
      </div>
    </div>
  )
}
export default BorrowingBaseL4
