import React from 'react'
import moment from 'moment'

import { TemplateProps } from '@interfaces/manage-reporting'
import { StyleSheet } from '@react-pdf/renderer'
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'

import { Footer } from '../../../components/footer'
import { styles } from '../../../template_style'

const tableStyles = StyleSheet.create({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid black',
    justifyContent: 'space-between',
  },

  tableCellContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '10%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid black',
  },

  tableTextCell: {
    ...styles.text,
    margin: 0,
    marginVertical: 2,
    fontSize: 10,
  },

  tableTextCellBold: {
    ...styles.textBold,
    margin: 0,
    marginVertical: 2,
    fontSize: 10,
  },
})

const CoignBackUpInterestTemplate = ({
  effectiveDate,
  formData,
  customData,
  agent,
  borrower,
}: TemplateProps) => {
  const formatCurrency = (amount: number) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount)
  }
  const year = moment.utc(effectiveDate).year()
  const currentMonth = moment.utc(effectiveDate).month()

  const firstOfQtrDate =
    currentMonth >= 0 && currentMonth <= 2
      ? `${year}-01-01`
      : currentMonth >= 3 && currentMonth <= 5
      ? `${year}-04-01`
      : currentMonth >= 6 && currentMonth <= 8
      ? `${year}-07-01`
      : `${year}-10-01`

  const nextQtrStartDate = moment
    .utc(firstOfQtrDate)
    .add(3, 'months')
    .format('MMMM D, YYYY')

  const getDisbursementTitle = (title: string) => {
    if (!title || !title.includes('-')) {
      return title ?? ''
    }
    const date = title.split(' ')[0].replace('disburse-', '')
    return `Disbursement ${date}`
  }

  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: 16,
          }}
          fixed
        >
          <Image
            src="/company-logos/coign-logo.jpg"
            style={{ width: 50, height: 50 }}
          />
        </View>
        <Text style={styles.text}>Subject: Backup Interest Calculation</Text>
        <Text style={styles.text}>Dear Marble Freedom Trust,</Text>
        <Text style={styles.text}>
          I hope this message finds you well. I am writing to provide you with a
          backup interest calculation for the Loan Agreement (this “Agreement”)
          made and entered into as of April 12, 2024 (the “Effective Date”), by
          and among {borrower}, a Delaware limited liability company
          (“Borrower”), Zing America, Inc., a Delaware corporation (“Parent”),
          in its capacity as a guarantor hereunder; {agent}, a Delaware limited
          liability company (“Lender”). This document serves as a supplementary
          verification of the interest accrued on the account, ensuring accuracy
          and transparency in our financial records. Please review the attached
          calculation and let me know if you have any questions or require
          further information.
        </Text>
        <Text style={styles.text}>
          The next NY Fed Term SOFR – 1m Reset date shall be {nextQtrStartDate}
        </Text>
        <Text style={styles.text}>
          Thank you for your attention to this matter.
        </Text>
        <Text style={styles.text}>Best regards,</Text>
        <Text style={styles.text}>{formData.replaceWithSignature}</Text>
        <Text style={styles.text}>-Borrower</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid black',
            width: '70%',
            marginBottom: 20,
          }}
          wrap={false}
        >
          <View style={tableStyles.tableRow}>
            <Text style={styles.text}>NY Fed Term SOFR - 1m</Text>
            <View
              style={{
                ...tableStyles.tableCellContainer,
                width: '23%',
                borderRight: 'none',
                borderLeft: '1px solid lightgray',
                alignItems: 'center',
              }}
            >
              <Text style={{ ...styles.text, margin: 0 }}>
                {moment.utc(firstOfQtrDate).format('MMMM D, YYYY')}
              </Text>
            </View>
            <View
              style={{
                ...tableStyles.tableCellContainer,
                borderLeft: '1px solid lightgray',
                borderRight: 'none',
                width: '30%',
                alignItems: 'flex-end',
                paddingRight: 10,
              }}
            >
              <Text style={{ ...styles.text, margin: 0 }}>
                {`${
                  customData?.cashflowData?.aggregated_data?.[0]?.data?.[0]
                    ?.fees?.[0]?.percentage_interest - 2.65
                }%`}
              </Text>
            </View>
          </View>
          <View style={tableStyles.tableRow}>
            <Text style={styles.text}>Margin</Text>
            <View
              style={{
                ...tableStyles.tableCellContainer,
                borderLeft: '1px solid lightgray',
                borderRight: 'none',
                width: '30%',
                alignItems: 'flex-end',
                paddingRight: 10,
              }}
            >
              <Text style={{ ...styles.text, margin: 0 }}>2.65000%</Text>
            </View>
          </View>
          <View style={tableStyles.tableRow}>
            <Text style={styles.text}>Interest for Period</Text>
            <View
              style={{
                ...tableStyles.tableCellContainer,
                borderLeft: '1px solid lightgray',
                borderRight: 'none',
                width: '30%',
                alignItems: 'flex-end',
                paddingRight: 10,
              }}
            >
              <Text style={{ ...styles.text, margin: 0 }}>
                {`${customData?.cashflowData?.aggregated_data?.[0]?.data?.[0]?.fees?.[0]?.percentage_interest}%`}
              </Text>
            </View>
          </View>
          <View style={tableStyles.tableRow}>
            <Text style={styles.text}>Interest Methodology</Text>
            <View
              style={{
                ...tableStyles.tableCellContainer,
                borderLeft: '1px solid lightgray',
                borderRight: 'none',
                width: '30%',
                alignItems: 'flex-end',
                paddingRight: 10,
              }}
            >
              <Text style={{ ...styles.text, margin: 0 }}>Actual/360</Text>
            </View>
          </View>
          <View style={tableStyles.tableRow}>
            <Text style={styles.text}>Interest Due for Period</Text>
            <View
              style={{
                ...tableStyles.tableCellContainer,
                borderLeft: '1px solid lightgray',
                borderRight: 'none',
                width: '30%',
                alignItems: 'flex-end',
                paddingRight: 10,
              }}
            >
              <Text style={{ ...styles.textBold, margin: 0 }}>
                {formatCurrency(customData?.cashflowData?.interest)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page style={styles.body}>
        {customData?.cashflowData?.aggregated_data?.map((cashflowData: any) => (
          <View
            key={cashflowData?.disbursement}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 16,
              width: '100%',
            }}
            wrap={false}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: '1px solid lightgray',
                borderRight: '1px solid lightgray',
                borderLeft: '1px solid lightgray',
              }}
            >
              <Text style={styles.textBold}>
                {getDisbursementTitle(cashflowData?.disbursement)}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                border: '1px solid black',
                width: '100%',
              }}
            >
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '9%',
                }}
              >
                <Text style={tableStyles.tableTextCellBold}>Lender</Text>
              </View>
              <View style={tableStyles.tableCellContainer}>
                <Text style={tableStyles.tableTextCellBold}>Start Date</Text>
              </View>
              <View style={tableStyles.tableCellContainer}>
                <Text style={tableStyles.tableTextCellBold}>End Date</Text>
              </View>
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '6%',
                }}
              >
                <Text style={tableStyles.tableTextCellBold}># Of Days</Text>
              </View>
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '15%',
                }}
              >
                <Text style={tableStyles.tableTextCellBold}>
                  Invested Amount
                </Text>
              </View>
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '15%',
                }}
              >
                <Text style={tableStyles.tableTextCellBold}>
                  Outstanding Principal
                </Text>
              </View>
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '35%',
                  borderRight: 'none',
                }}
              >
                <Text style={tableStyles.tableTextCellBold}>Fees</Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                border: '1px solid black',
                borderTop: 'none',
                width: '100%',
              }}
            >
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '9%',
                }}
              >
                <Text style={tableStyles.tableTextCell}>
                  {cashflowData?.data?.[0]?.investor}
                </Text>
              </View>
              <View style={tableStyles.tableCellContainer}>
                <Text style={tableStyles.tableTextCell}>
                  {moment
                    .utc(cashflowData?.data?.[0]?.start_date)
                    .format('M/DD/YYYY')}
                </Text>
              </View>
              <View style={tableStyles.tableCellContainer}>
                <Text style={tableStyles.tableTextCell}>
                  {moment
                    .utc(cashflowData?.data?.[0]?.end_date)
                    .format('M/DD/YYYY')}
                </Text>
              </View>
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '6%',
                }}
              >
                <Text style={tableStyles.tableTextCell}>
                  {cashflowData?.data?.[0]?.no_of_days}
                </Text>
              </View>
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '15%',
                }}
              >
                <Text style={tableStyles.tableTextCell}>
                  {formatCurrency(cashflowData?.data?.[0]?.invested_amount)}
                </Text>
              </View>
              <View
                style={{
                  ...tableStyles.tableCellContainer,
                  width: '15%',
                }}
              >
                <Text style={tableStyles.tableTextCell}>
                  {formatCurrency(
                    cashflowData?.data?.[0]?.outstanding_principal
                  )}
                </Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '35%',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid black',
                    width: '100%',
                  }}
                >
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '30%',
                      borderRight: '1px solid lightgray',
                    }}
                  >
                    <Text style={tableStyles.tableTextCell}> </Text>
                  </View>
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '35%',
                      borderRight: '1px solid lightgray',
                    }}
                  >
                    <Text style={tableStyles.tableTextCell}> </Text>
                  </View>
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '35%',
                      borderRight: 'none',
                    }}
                  >
                    <Text style={tableStyles.tableTextCell}> </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid black',
                    width: '100%',
                  }}
                >
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '30%',
                    }}
                  >
                    <Text style={tableStyles.tableTextCell}>Fee Type</Text>
                  </View>
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '35%',
                    }}
                  >
                    <Text style={tableStyles.tableTextCell}>Percentage</Text>
                  </View>
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '35%',
                      borderRight: 'none',
                    }}
                  >
                    <Text
                      style={{
                        ...styles.text,
                        margin: 0,
                        fontSize: 10,
                      }}
                    >
                      Amount
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid black',
                    width: '100%',
                  }}
                >
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '30%',
                    }}
                  >
                    <Text style={tableStyles.tableTextCell}>Interest</Text>
                  </View>
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '35%',
                    }}
                  >
                    <Text style={tableStyles.tableTextCell}>
                      {`${cashflowData?.data?.[0]?.fees?.[0]?.percentage_interest.toFixed(
                        5
                      )}%`}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...tableStyles.tableCellContainer,
                      width: '35%',
                      borderRight: 'none',
                    }}
                  >
                    <Text
                      style={{
                        ...styles.text,
                        margin: 0,
                        fontSize: 10,
                      }}
                    >
                      {formatCurrency(
                        cashflowData?.data?.[0]?.fees?.[0]?.amount
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        ))}
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            position: 'absolute',
            bottom: 100,
            right: 30,
          }}
          wrap={false}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              <Text style={{ ...styles.text, marginBottom: 0 }}>
                Approval:{' '}
              </Text>
              <View style={{ borderBottom: '1px solid black' }}>
                <Text
                  style={{
                    ...styles.signTags,
                    marginBottom: 0,
                    width: 100,
                  }}
                >
                  [initial|req|signer1]
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

export default CoignBackUpInterestTemplate
